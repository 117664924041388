import { encryptData, decryptData } from '@/utils/crypto';

export const setItem = (name, value) => {
    if (typeof value === 'object') {
        value = JSON.stringify(value)
    }
    // 数据加密
    value = encryptData(value)
    window.localStorage.setItem(name, value)
}

export const getItem = name => {
    let data = window.localStorage.getItem(name)
    // console.log('获取本地存储的user信息',data)
    try {
        if (!data) return null
        // 数据解密
        // console.log(`${name} 加密前：`,data)
        data = decryptData(data)
        // console.log(`${name} 解密后：`,data)
        return JSON.parse(data)
    } catch (err) {
        // console.log('解析错误',err)
        // return data
        return null
    }
}



export const removeItem = name => {
    window.localStorage.removeItem(name)
}
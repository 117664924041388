import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'



// 当你运行 npm run serve 时，Vue CLI Service 会自动设置 NODE_ENV 为 'development'
if (process.env.NODE_ENV === 'development') {
  // import('./mock')
}

// css初始化
import 'normalize.css/normalize.css'

// 引入vant
import Vant from 'vant'
import 'vant/lib/index.css'
Vue.use(Vant)

// 加载comm.css
import '@/assets/css/comm.less'
// 设置rem基准值
import 'amfe-flexible'

// 处理日期插件
// 方式一： import './utils/dayjs'
// 方式二：
import dateTime from './utils/date-time'
Vue.use(dateTime)


// import LuckDraw from 'vue-luck-draw'
// Vue.use(LuckDraw)

// import Vue2TouchEvents from 'vue2-touch-events'
// Vue.use(Vue2TouchEvents);


//统一接口api文件夹里面全部请求函数
//统一引入
// import * as REQUEST from '@/api/requestAxios';
import HTTP from '@/api/index';


Vue.config.productionTip = false

new Vue({
  //全局事件总线$bus配置
  beforeCreate() {
    Vue.prototype.$bus = this;
    // Vue.prototype.$REQUEST = REQUEST;
    Vue.prototype.$HTTP = HTTP;
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')

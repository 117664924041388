/**
 * HttpService.js 普通封装
 */
import config from '@/config';
import HttpService from '@/lib/HttpService';
const httpService = new HttpService(config.baseUrl);
export default httpService;


/**
 * HttpAll.js 封装了多个异步请求的请求队列loadading
 */
// import HttpAll from '@/lib/HttpAll'
// const httpAll = new HttpAll();
// httpAll.interceptorsRequest(() => {
//     // 自定义回调在请求前可以做些什么
// })
// httpAll.interceptorsResponse(() => {
//     // 自定义回调在响应后可以做些什么
// })
// export default httpAll;
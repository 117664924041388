const routes = [
	// 仿法行宝 v5
	{
		path: "/v5",
		name: "v5",
		component: () => import("../views/v5/layout"),
		redirect: "/v5/index",
		children: [
			{
				path: "index",
				name: "v5Index",
				component: () => import("../views/v5/index/index.vue"),
				meta: { v5Auth: false, v5Labor: false },
			},
			{
				path: "functionPage",
				name: "v5functionPage",
				component: () => import("../views/v5/functionPage/index2.vue"),
				meta: { v5Auth: false, v5Labor: false },
			},
			// 问题提交
			{
				path: "problems",
				name: "v5problems",
				component: () => import("../views/v5/problems/index3.vue"),
				meta: { v5Auth: true, v5Labor: true },
			},
			// 意见书pdf
			{
				path: "pdfView",
				name: "v5pdfView",
				component: () => import("../views/v5/pdfView/index8.vue"),
				meta: { v5Auth: true },
			},
			// 劳动关系确认页
			{
				path: "laborRelations",
				name: "v5laborRelations",
				component: () => import("../views/v5/laborRelations/index10.vue"),
				meta: { v5Auth: true, v5Labor: false, isCache: true },
			},
			// 工资问题
			{
				path: "wages",
				name: "wages",
				component: () => import("../views/v5/wages/index5.vue"),
				meta: { v5Auth: true, v5Labor: false, isCache: true },
			},
			// 调岗调薪
			{
				path: "adjustment",
				name: "adjustment",
				component: () => import("../views/v5/adjustment/index2.vue"),
				meta: { v5Auth: true, v5Labor: true, isCache: true },
			},
			// 五险一金
			{
				path: "insurance",
				name: "insurance",
				component: () => import("../views/v5/insurance/index1.vue"),
				meta: { v5Auth: true, v5Labor: true, isCache: true },
			},
			// 试用期问题
			{
				path: "probation",
				name: "probation",
				component: () => import("../views/v5/probation/index1.vue"),
				meta: { v5Auth: true, v5Labor: true, isCache: true },
			},
			// 双倍工资
			{
				path: "doubleWages",
				name: "doubleWages",
				component: () => import("../views/v5/doubleWages/index3.vue"),
				meta: { v5Auth: true, v5Labor: true, isCache: true },
			},
			// 假期问题
			{
				path: "holiday",
				name: "holiday",
				component: () => import("../views/v5/holiday/index1.vue"),
				meta: { v5Auth: true, v5Labor: true },
			},
			//劳动合同问题
			{
				path: "laborContract",
				name: "laborContract",
				component: () => import("../views/v5/laborContract/index9.vue"),
				meta: { v5Auth: true, v5Labor: true },
			},
			// 辞职辞退问题
			{
				path: "severancepayments",
				name: "severancepayments",
				component: () => import("../views/v5/severancepayments/index1.vue"),
				meta: { v5Auth: true, v5Labor: true },
			},
			//离职时的补偿和赔偿
			{
				path: "economic",
				name: "economic",
				component: () => import("../views/v5/economic/index4.vue"),
				meta: { v5Auth: true, v5Labor: true },
			},
			// 文章模块
			{
				path: "article",
				name: "article",
				component: () => import("../views/v5/article/index2.vue"),
				meta: { v5Auth: true, v5Labor: true },
			},

			// 测试测试
			{
				path: "ceshi",
				name: "ceshi",
				component: () => import("../views/v5/article/index2.vue"),
				meta: { v5Auth: true, v5Labor: true },
			},
			// // demo页面，测试组件化
			// {
			//     path: 'demo',
			//     name: 'v5demo',
			//     component: () => import('../views/v5/demo/index12.vue'),
			//     meta: { v5Auth: true, v5Labor: false }
			// },
			// // Wdemo页面，测试组件化
			// {
			//     path: 'Wdemo',
			//     name: 'v5Wdemo',
			//     component: () => import('../views/v5/Wdemo/index3.vue'),
			//     meta: { v5Auth: false, v5Labor: false }
			// },
			// // Ldemo页面，测试组件化
			// {
			//     path: 'Ldemo',
			//     name: 'v5Ldemo',
			//     component: () => import('../views/v5/Ldemo/Ltest.vue'),
			//     meta: { v5Auth: false, v5Labor: false }
			// },
			// // Ldemo页面，测试组件化
			// {
			//     path: 'Lcsdemo',
			//     name: 'v5Lcsdemo',
			//     component: () => import('../views/v5/Ldemo/Ltest2.vue'),
			//     meta: { v5Auth: false, v5Labor: false }
			// },
		],
	},

	{
		path: "/v4/t2t2",
		name: "v4t2t2",
		component: () => import("../views/v4/testtest/t2t2002.vue"),
		meta: { requiresAuth: false },
	},

	// 404页面，跳转到index
	{
		path: "*",
		redirect: "/v5",
		// redirect: '/v3'
	},
];

export default routes;

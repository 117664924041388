<template>
	<div id="app">
		<!-- 显示 -->
		<router-view />
		<!-- 显示 -->
	</div>
</template>

<script>
	export default {
		name: "App",
		components: {},
		data() {
			return {};
		},
		computed: {},
		methods: {},
		created() {},
		mounted() {},
		watch: {},
	};
</script>

<style lang="less"></style>

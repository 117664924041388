const axios = require('axios');

import store from '@/store';

// 引入vant加载，处理请求时页面
import { Toast } from 'vant';


class HttpService {
  constructor(baseURL, token = '') {

    this.pendingRequests = 0;  // 初始化待处理请求计数器

    this.apiClient = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    // 添加请求拦截器
    this.apiClient.interceptors.request.use(
      config => {

        if (this.pendingRequests === 0) {
          // 如果没有待处理请求，则显示加载消息
          // Toast.loading({
          //   // message: '加载中...',
          //   forbidClick: true,
          //   duration: 0,
          // });
        }
        this.pendingRequests++;  // 每个新请求增加计数器
        // console.log('请求开始', this.pendingRequests);

        // if (!this.loading) {
        //   this.loading = Toast.loading({
        //     message: '加载中...',
        //     forbidClick: true,
        //     duration: 0,
        //     overlay: true
        //   })
        // }


        const user = store.state.user
        if (user) {
          // config.headers.Authorization = `Bearer ${user.token}`
        }

        return config;

      },
      error => {

        this.pendingRequests = Math.max(0, this.pendingRequests - 1);  // 如果请求出现错误，减少计数器

        // 对请求错误进行处理
        return Promise.reject(error);

      }
    );

    // 添加响应拦截器
    this.apiClient.interceptors.response.use(
      response => {
        // // // 对响应数据进行处理
        // this.pendingRequests = Math.max(0, this.pendingRequests - 1);  // 成功响应后减少计数器
        // if (this.pendingRequests === 0) {
        //   Toast.clear();  // 只有在没有待处理请求时才清除加载消息
        // }
        // Toast.clear();

        // console.log('请求结束');
        // console.log(response);
        return response.data;
      },
      error => {

        // this.pendingRequests = Math.max(0, this.pendingRequests - 1);  // 减少计数器以处理错误响应
        // if (this.pendingRequests === 0) {
        //   Toast.clear();  // 如果没有待处理请求，则清除最后一个待处理请求的加载消息
        // }

        return Promise.reject(error);
      }
    );

  }

  // 使用request方法，可以在请求前后做一些事情
  request(config) {
    return this.apiClient.request(config);
  }

  async get(endpoint) {
    const response = await this.apiClient.get(endpoint);
    return response;
  }

  async post(endpoint, payload) {
    const response = await this.apiClient.post(endpoint, payload);
    return response;
  }

}

export default HttpService;

// 导出配置项
export default {
    // 服务器地址 本地测试时可以调用vue.config.js中的代理配置
    // baseUrl: process.env.NODE_ENV === 'production' ? 'https://shantai.taishan.com.cn/' : '/shantai',

    //TODO: 2024-04-01，线上地址是动态域名，如 2.shantai.taishan.com.cn/
    baseUrl: process.env.NODE_ENV === 'production' ? '/' : '/shantai',

    USER_KEY: 'shangtaiQuestion',
    imgUrl: 'https://shantai.taishan.com.cn/pan/wxapp/shantai/',
    // 公众号红包链接地址
    RED_PACKET_URL: 'https://op.tiantianfawu.net/auth/redEnvelopeFission?id=1&target',
    // 页面数据存储
    PAGE_DATA_KEY: 'shangtaiQuestionOther',
    // 微信用户信息
    WX_INFO_KEY: 'shangtaiQuestionWechat',
    // 过期时间
    SHANGTAI_EXPIRATION_TIME: 'shangtaiExpirationTime',
    // 版本v2首页数据存储
    SHANGTAI_VERSION2_INDEX: 'shangtaiVersion2Index',

    // v4版本
    V4_USER_KEY:'ST_V4_USER',
    // v4版本劳动关系
    V4_LABOR_KEY:'ST_V4_LABOR',

    // v5版本
    V5_USER_KEY:'ST_V5_USER',


}

import CONFIG from '@/config'

const getters = {
    // 获取用户信息
    user: state => state.user,
    // 获取公众号红包信息
    redPacket: state => state.redPacket,
    // 获取红包链接
    redPacketLink: state => {
        if (!state.redPacket) return '';

        let red_packet = state.redPacket;
        let red_packet_param = `/redEnvelope/speed?id=${red_packet.fissionId}&fissionContactId=${red_packet.fissionContactId}&type=`;
        // console.log('store red_packet', red_packet_param);
        // url编码
        red_packet_param = encodeURIComponent(red_packet_param);

        return `${CONFIG.RED_PACKET_URL}=${red_packet_param}`;
    },

    // v4版本
    v4User: state => state.v4User,
    // v4版本劳动关系
    v4Labor: state => state.v4Labor,

    // v5版本
    v5User: state => state.v5User,

}

export default getters
